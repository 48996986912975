import React, { useState } from "react";
import axios from "axios"; // Import the axios library
import { toast } from "react-toastify";
import Link from "next/link";

function Footer() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate email
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      toast.error("Please enter a valid email address");
      return;
    }

    try {
      await axios.post("/api/subscription/subscribe", { email });
      setMessage("Email subscribed successfully");
      setEmail("");
      toast.success("Email subscribed successfully");
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <>
      <footer className="landing-footer" id="contact">
        <div class="container">
          <div class="row">
            <div class="col first">
              <div className="lef-col">
                <figure>
                  <img
                    src="public/images/logo/new-logo/logo-small.png"
                    alt=" agricare logo"
                    width="180"
                  />
                </figure>
                <p>Subscribe to our newsletter to get the latest updates and news</p>
                <div className="subscribe-box">
                  <input
                    type="email"
                    name=""
                    id=""
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <button onClick={handleSubmit}>Subscribe</button>
                </div>
                <p>{message}</p>
              </div>
            </div>
            <div class="col">
              <h3>About Us</h3>
              <ul>
                <li>
                  <a href="#how-work">How it works</a>
                </li>
                <li>
                  <a href="#why-choose">Why Choose Us</a>
                </li>
                <li>
                  <a href="#mission">Mission</a>
                </li>
                <li>
                  <a href="#mission">Vision</a>
                </li>
              </ul>
            </div>
            <div class="col">
              <h3>Legal</h3>
              <ul>
                <li>
                  <Link href="/privacy">Privacy Policy</Link>
                </li>
                <li>
                  <Link href="/terms-condition">Terms & Conditions</Link>
                </li>
                <li>
                  <Link href="/faq-landing">FAQ</Link>
                </li>
              </ul>
            </div>
            <div class="col">
              <h3>Contact Info</h3>
              <ul>
                <li>
                  <a href="">
                    {/* SONARTORI TOWER (14TH FLOOR),12 BIPONON C/A, SONARGAON ROAD, BANGLAMOTOR, DHAKA */} 
                    Sonartori Tower (14th Floor), 12 Biponon C/A, Sonargaon Road, Banglamotor, Dhaka, Bangladesh
                  </a>
                </li>
                <li>
                  <a href="tel:01329713966" id="number" className="icon-mail">
                    <span className="icon">
                      <svg
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.7208 10.5087C13.7409 10.5087 12.7788 10.3554 11.8671 10.054C11.4203 9.9016 10.8711 10.0414 10.5984 10.3216L8.79879 11.6805C6.71175 10.5661 5.42618 9.28055 4.32733 7.20854L5.64587 5.45528C5.98844 5.11307 6.11131 4.61317 5.9641 4.14413C5.6615 3.22731 5.50779 2.26533 5.50779 1.28472C5.50783 0.576318 4.93169 0 4.22355 0H1.28428C0.576141 0 0 0.576318 0 1.28468C0 9.40433 6.60368 16.01 14.7208 16.01C15.429 16.01 16.0051 15.4337 16.0051 14.7253V11.7934C16.0051 11.085 15.4289 10.5087 14.7208 10.5087Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                    <span className="text">01329713966</span>
                  </a>
                </li>
                <li>
                  <a href="mailto:example@mail.com" className="icon-mail">
                    <span className="icon">
                      <svg
                        width="19"
                        height="13"
                        viewBox="0 0 19 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.392303 1.04278C2.96897 3.0346 7.49021 6.53881 8.81906 7.63143C8.99745 7.7789 9.18879 7.85387 9.38742 7.85387C9.58565 7.85387 9.77665 7.7796 9.95464 7.63283C11.2846 6.53914 15.8059 3.0346 18.3826 1.04278C18.543 0.919011 18.5675 0.701458 18.4376 0.550141C18.1373 0.200483 17.6897 0 17.2099 0H1.56491C1.08518 0 0.637503 0.200484 0.337301 0.550175C0.207423 0.701458 0.231881 0.919011 0.392303 1.04278Z"
                          fill="white"
                        />
                        <path
                          d="M18.5472 2.11922C18.4085 2.06031 18.2454 2.08086 18.1301 2.17083C15.2726 4.38191 11.6257 7.21605 10.4783 8.1598C9.83427 8.69043 8.94048 8.69043 8.29498 8.1591C7.07195 7.15326 2.97657 3.97538 0.643965 2.1708C0.527837 2.08083 0.364371 2.06098 0.226865 2.11919C0.0886267 2.17779 0 2.30401 0 2.44311V11.4214C0 12.209 0.701644 12.8494 1.56452 12.8494H17.2096C18.0724 12.8494 18.7741 12.209 18.7741 11.4214V2.44311C18.7741 2.30401 18.6854 2.17746 18.5472 2.11922Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                    <span className="text">support@agricare.global</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
