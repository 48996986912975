import React from 'react';

function Hero() {
  return (
    <>
    <div className="landing-hero" id='home'>
      <div className="left-column"></div>
      <div className="right-column">
       <h1 className="heroTitle"> building <span className="nation"> nation </span>through sustainable <span className="agro">Agri Finance Solutions</span> </h1>
      </div>
    </div>
   
    </>
  );
}

export default Hero;

