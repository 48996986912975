import Image from "next/image";
import React from "react";

function HowIt() {
  return (
    <section className="howWorks who-we" id="who-we">
      <div className="container">
        <div className="howWorks-wrapper">
          <div className="section-top">
            <h1 className="section-title" style={{ textAlign: "left" }}>
              কৃষি যত্ন <span>কি </span>
            </h1>
            <p className="section-des" id="mission">
              কৃষি যত্ন হলো একটি উপায় বা পদক্ষেপ যা বাংলাদেশের কৃষকদের ফসলের উৎপাদন ও বিক্রির
              প্রক্রিয়ায় সহায়ক করে, এবং তাদেরকে উন্নত জীবন সেবা প্রদান করে।
            </p>
          </div>
          <div className="section-content">
            <div class="who-we-container">
              <div class="column">
                <h3>কৃষি যত্নের কাজ কি? </h3>
                <span className="icon">
                  <Image src="public/assets/icons/mission.svg" alt="" width={90} height={90} />
                </span>
                <span className="text">
                  ১। বাংলাদেশের প্রতিটি কৃষকের ফসলের উৎপাদন ও বিক্রি নিয়ে সহায়তা করা এবং কৃষিযত্ন
                  নিজ উদ্যোগে ফসলের উৎপাদন করা ও কৃষকের ফসল ক্রয় করে পাইকারি বাজারে সরবরাহ করা।{" "}
                  <br /> <br />
                  ২।সাথে ভালো মানের বীজ কৃষক এর কাছে পৌঁছে দেয়া।
                </span>
              </div>
              <div class="column">
                <h3>কৃষি যত্ন থেকে কি সুবিধা পাওয়া যাবে</h3>
                <span className="icon">
                  <Image src="public/assets/icons/vision.svg" alt="vision" width={90} height={90} />
                </span>
                <span className="text">
                  ১. কৃষক ফসল ফলানোর সয়েল টেস্ট করানো। <br />
                  ২.কৃষককে ভালো মানের বীজ সরবরাহ। <br />
                  ৩। কৃষককে মাঠ থেকে ফসল বিক্রি করতে সহায়তা প্রদান। <br />
                  ৪। কৃষকের উৎপাদিত ফসল পাইকারি বাজারে সরবরাহের সুবিধা করে দেওয়া । <br />
                  ৫। কৃষককে উন্নত মানের ফসল উৎপাদনের প্রশিক্ষণ প্রদান করা। <br />
                  ৬। কৃষি যত্ন থেকে কৃষকের উন্নত জীবন সেবা নিশ্চিত করা । <br />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HowIt;
