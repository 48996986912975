import Image from 'next/image'
import React from 'react'

function WhoWe() {
  return (
    <section className="howWorks who-we" id='who-we'>
      <div className="container">
        <div className="howWorks-wrapper">
          <div className="section-top">
            <h1 className="section-title" style={{ textAlign: "left" }}>
              Who We <span>are </span>
            </h1>
            <p className="section-des" id='mission'>
              AgriCare is a technology based Agri Solution company that finances small-scale farmers
              to maximize their profit with the supervision of experts.
            </p>
          </div>
          <div className="section-content" >
            <div class="who-we-container">
              <div class="column">
                <h3>our mission</h3>
                <span className="icon">
                  <Image src="public/assets/icons/mission.svg" alt="" width={90} height={90} />
                </span>
                <span className="text">
                    Our mission is to bridge the financial gap for farmers in Bangladesh, enabling
                    them to access affordable and convenient financial services through AgriCare
                    platforms. We strive to enhance their financial resilience, increase productivity,
                    and promote sustainable farming practices while contributing to the overall
                    economic development of rural communities.
                </span>
              </div>
              <div class="column">
                <h3>our Vision</h3>
                <span className="icon">
                  <Image src="public/assets/icons/vision.svg" alt="vision" width={90} height={90} />
                </span>
                <span className="text">
                  Our vision is to be the leading AgriTech company in Bangladesh, leveraging
                  technology and financial innovation to revolutionize the agricultural sector. By
                  providing tailored financial solutions, we aim to empower farmers, foster
                  financial inclusion, and drive sustainable agricultural growth across the country
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhoWe