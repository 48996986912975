import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';

const FaqSection = () => {
  const [faqs, setFaqs] = useState([]);
  const [expandedIndex, setExpandedIndex] = useState(null);

  useEffect(() => {
    axios.get('/api/faq/get-all-faqs')
      .then(response => {
        setFaqs(response.data);
      })
      .catch(error => {
        console.error('Error fetching FAQs:', error);
      });
  }, []);

  const handleChange = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  return (
    <section className="faq-section" id="faq">
      <div className="container">
      <div class="section-top">
          <h1 class="section-title">Frequently asked questions</h1>
          <p class="section-des">Find answers to some frequently asked questions about Agricare</p>
        </div>
        {faqs.map((faq, index) => (
          <Accordion
            key={index}
            expanded={index === expandedIndex}
            onChange={() => handleChange(index)}
            sx={{
              borderRadius: '8px',
              marginBottom: '8px',
              paddingBlock:'8px',
              borderTop: '4px solid transparent',
              '&.Mui-expanded': {
                borderTop: '4px solid #12BA5A', // Add a top border for the active accordion
              },
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`faq-content-${index}`}>
              <p className='faq-question'>{faq.question}</p>
            </AccordionSummary>
            <AccordionDetails>
              <p className='faq-ans'>{faq.answer}</p>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </section>
  );
};

export default FaqSection;

