import axios from 'axios';
import React, { useEffect, useState } from 'react'

function TotalDetail() {
  const [statistics,setStatisitcs]=useState([]);

  useEffect(()=> {
    const fetchStatistics= async ()=> {
      try {
        const response = await axios.get('/api/statistics');
        setStatisitcs(response.data);
        // console.log(response);
        setLoading(false);
      } catch(error) {
        console.log('Error fetching statistics:', error);
      }
    }
    fetchStatistics();
  },[])
  return (
    <section className="totalDetails" id='about'>
    <div className="total-cards">
      <div className="total-card">
        <div className="left-icon green">
          <svg
            width="23"
            height="28"
            viewBox="0 0 23 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.37243 10.4714C9.71369 11.8061 12.4486 11.7915 13.7599 10.4714C14.4493 11.1351 15.0038 11.9009 15.4833 12.7177C17.0044 15.2921 18.016 18.0489 18.5929 20.9661C18.9376 22.7164 18.2033 24.0802 16.937 25.2252C15.5732 26.4505 13.9473 27.136 12.1115 27.3256C9.51887 27.5955 7.19602 26.9391 5.23285 25.2398C4.37115 24.4959 3.73424 23.5916 3.51694 22.4612C3.38956 21.7683 3.50945 21.0755 3.6668 20.4045C4.32619 17.4727 5.37522 14.6868 7.03118 12.1342C7.30842 11.7112 7.60814 11.3101 7.94533 10.9309C8.0802 10.7704 8.22257 10.6246 8.37243 10.4714ZM11.0624 23.6281C13.168 23.6281 14.8839 21.9725 14.8914 19.9159C14.8989 17.852 13.183 16.1673 11.0699 16.1673C8.96438 16.1673 7.24848 17.8301 7.24098 19.8794C7.23349 21.9507 8.9419 23.6208 11.0624 23.6281Z"
              fill="#12BA5A"
            />
            <path
              d="M9.78859 5.51225C9.9984 4.87775 10.3206 4.35266 10.7627 3.8932C10.98 3.66711 11.1598 3.66711 11.3771 3.8932C11.8117 4.35266 12.1339 4.88505 12.3362 5.51225C12.7858 5.30075 13.2504 5.1476 13.7449 5.08925C13.9173 5.06737 14.0971 5.06008 14.2769 5.05279C14.5092 5.04549 14.6216 5.16948 14.6441 5.38097C14.7115 6.07381 14.2769 7.25528 13.7899 7.74392C12.4786 6.37283 9.68369 6.35824 8.33495 7.75121C7.8479 7.19694 7.61561 6.54786 7.5257 5.84043C7.42829 5.06008 7.55567 4.95798 8.35742 5.08196C8.85197 5.1476 9.32403 5.27887 9.78859 5.51225Z"
              fill="#12BA5A"
            />
            <path
              d="M11.0474 10.5883C10.4479 10.581 9.86348 10.4789 9.34646 10.1434C8.49975 9.59644 8.49975 8.56812 9.34646 8.01385C10.2531 7.42312 11.8866 7.42312 12.7933 8.01385C13.64 8.56812 13.64 9.58915 12.7933 10.1434C12.2613 10.4862 11.6693 10.5883 11.0474 10.5883Z"
              fill="#12BA5A"
            />
            <path
              d="M4.45363 1.12195C4.3787 1.21676 4.31875 1.31886 4.22884 1.39908C4.08647 1.53036 3.92162 1.53036 3.77925 1.39908C3.63689 1.26781 3.6219 1.10007 3.75677 0.961502C4.049 0.662488 4.34123 0.370766 4.64845 0.0936311C4.79831 -0.0449365 4.97814 -0.0230571 5.128 0.11551C5.40524 0.385352 5.68249 0.655195 5.95973 0.925037C6.10959 1.0709 6.12457 1.23864 5.98221 1.3845C5.83984 1.53765 5.66001 1.53036 5.51015 1.39179C5.42772 1.31886 5.36778 1.22405 5.28535 1.12924C5.18045 1.25322 5.21792 1.35532 5.21792 1.44284C5.21792 3.65992 5.21792 5.86971 5.21792 8.08679C5.21792 8.15243 5.21792 8.21806 5.21792 8.27641C5.21042 8.56084 5.08304 8.72857 4.86574 8.7067C4.60349 8.67752 4.55104 8.50249 4.55104 8.27641C4.55104 6.00098 4.55104 3.71827 4.55104 1.44284C4.55104 1.35532 4.55104 1.26781 4.55104 1.18029C4.51357 1.16571 4.4836 1.14383 4.45363 1.12195Z"
              fill="#12BA5A"
            />
            <path
              d="M16.9298 1.1293C16.7799 1.26787 16.69 1.36268 16.5851 1.43561C16.4353 1.545 16.2779 1.53042 16.158 1.39914C16.0306 1.26787 16.0232 1.10742 16.143 0.976146C16.4353 0.669839 16.7425 0.370825 17.0572 0.086397C17.1921 -0.0302915 17.3569 -0.0229991 17.4918 0.0936893C17.7915 0.370824 18.0837 0.655252 18.3685 0.946973C18.5108 1.09283 18.5183 1.26787 18.361 1.40643C18.2036 1.55229 18.0388 1.53042 17.8889 1.38456C17.8065 1.31162 17.7391 1.22411 17.6566 1.1293C17.5517 1.25328 17.5967 1.35538 17.5967 1.45019C17.5967 3.68915 17.5967 5.93541 17.5967 8.17437C17.5967 8.26188 17.6042 8.35669 17.5892 8.44421C17.5667 8.61195 17.4468 8.69946 17.282 8.71405C17.1246 8.72134 17.0047 8.64841 16.9598 8.49526C16.9298 8.39316 16.9298 8.27647 16.9298 8.17437C16.9298 5.9427 16.9298 3.71103 16.9298 1.47207C16.9298 1.37726 16.9298 1.28245 16.9298 1.1293Z"
              fill="#12BA5A"
            />
            <path
              d="M1.56138 7.46689C1.50144 7.56169 1.53141 7.6638 1.53141 7.75861C1.53141 9.98298 1.53141 12.2001 1.53141 14.4244C1.53141 14.8547 1.44898 15.0079 1.20921 15.0152C0.961937 15.0225 0.872021 14.862 0.872021 14.4098C0.872021 12.2001 0.872021 9.98298 0.872021 7.77319C0.872021 7.67838 0.872021 7.57628 0.872021 7.48147C0.842049 7.45959 0.812077 7.43771 0.782105 7.41584C0.707174 7.51794 0.64723 7.62733 0.557314 7.71485C0.414946 7.83883 0.242606 7.83883 0.107731 7.70026C-0.0346364 7.5617 -0.0346363 7.39396 0.100238 7.26268C0.392467 6.96367 0.684696 6.67195 0.99191 6.39481C1.13428 6.26354 1.30662 6.27812 1.44899 6.4094C1.74121 6.67924 2.01846 6.95638 2.30319 7.2408C2.44556 7.38666 2.46804 7.5544 2.31818 7.70026C2.16082 7.86071 1.98848 7.83154 1.82364 7.68568C1.73372 7.60545 1.67378 7.50335 1.56138 7.46689Z"
              fill="#12BA5A"
            />
            <path
              d="M21.2606 7.41602C21.2606 7.59105 21.2606 7.68586 21.2606 7.78796C21.2606 9.99775 21.2606 12.2148 21.2606 14.4246C21.2606 14.4975 21.2606 14.5705 21.2606 14.6434C21.2531 14.8549 21.1707 15.0081 20.9384 15.0081C20.6911 15.0081 20.6012 14.8476 20.6012 14.6288C20.6012 13.7974 20.6012 12.9733 20.6012 12.1419C20.6012 10.676 20.6012 9.2101 20.6012 7.7442C20.6012 7.65669 20.6012 7.56917 20.6012 7.40872C20.4663 7.54 20.3764 7.62751 20.2865 7.70044C20.1366 7.82443 19.9718 7.83172 19.8294 7.69315C19.687 7.56188 19.6795 7.39414 19.8144 7.25557C20.1066 6.95655 20.3989 6.66483 20.7061 6.3877C20.8559 6.24913 21.0358 6.27101 21.1856 6.40958C21.4704 6.67942 21.7476 6.94926 22.0174 7.2191C22.1747 7.37226 22.1897 7.54729 22.0249 7.70044C21.8675 7.83901 21.7027 7.81713 21.5528 7.67857C21.4704 7.60564 21.3954 7.5327 21.2606 7.41602Z"
              fill="#12BA5A"
            />
            <path
              d="M14.2244 19.9234C14.2394 18.2168 12.8232 16.8238 11.0698 16.8165C9.32393 16.8165 7.91524 18.1876 7.90774 19.8869C7.90025 21.5789 9.30894 22.9718 11.0473 22.9864C11.7592 22.9937 12.4186 22.7603 12.9581 22.3665C13.7148 21.8123 14.2169 20.9225 14.2244 19.9234Z"
              fill="#12BA5A"
            />
          </svg>
        </div>
        <div className="right-content">
          <h3 className="title">{statistics[0]?.title}</h3>
          <p className="des">{statistics[0]?.description}</p>
        </div>
      </div>
      <div className="total-card">
        <div className="left-icon danger">
          <svg
            width="22"
            height="25"
            viewBox="0 0 22 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.4896 12.849C17.4023 12.849 14.3151 12.849 11.2279 12.849C8.10111 12.849 4.97431 12.849 1.84751 12.849C1.05592 12.849 0.976762 12.9282 1.17466 13.7198C2.04541 17.2423 2.95575 20.7649 3.8265 24.2875C3.98482 24.8812 4.2223 25 4.77641 25C9.01144 25 13.286 24.9604 17.5211 25C18.1544 25 18.3918 24.7625 18.5501 24.1688C19.4209 20.6858 20.2917 17.2028 21.1624 13.7198C21.3999 12.9282 21.3603 12.849 20.4896 12.849Z"
              fill="#FB3638"
            />
            <path
              d="M5.56807 11.9782H6.63672C6.83462 11.9782 6.99294 11.9782 7.19084 11.9782H9.2094L8.53655 10.7513C8.49697 10.6721 8.49697 10.6325 8.45739 10.5534C8.33865 10.3159 8.25949 10.0784 8.10117 9.92009C7.90328 9.76178 7.6658 9.68262 7.27 9.68262C6.39925 9.76178 5.68681 9.24724 5.09311 8.49522C6.04303 8.17859 6.79504 8.45565 7.6658 8.97018C7.34916 8.33691 7.0721 7.90153 6.83462 7.42657C6.6763 7.07036 6.4784 6.9912 6.08261 7.03078C5.05354 7.10994 4.26194 6.63498 3.58909 5.8038C4.49942 5.52675 5.25143 5.72465 6.04303 6.16002C5.84513 5.64549 5.56807 5.21011 5.33059 4.73515C5.17228 4.37894 4.9348 4.29978 4.57858 4.33936C3.58909 4.37894 2.79749 3.94356 2.16422 3.15197C2.95581 2.79575 3.62867 3.15197 4.3411 3.2707C3.9453 2.47911 3.39119 1.84583 2.75791 1.29172C2.36212 0.935502 1.88716 0.737603 1.33304 1.01466C0.778927 1.3313 0.699768 1.84583 0.778928 2.39995C0.858087 2.87491 0.976826 3.31028 1.13514 3.74566C2.2038 6.51624 3.70783 9.08892 5.17227 11.622C5.21185 11.8199 5.33059 11.9782 5.56807 11.9782Z"
              fill="#FB3638"
            />
            <path
              d="M7.78456 2.71645C12.455 0.618722 17.4024 2.95392 18.9065 7.94097C19.3023 9.2471 19.4606 10.5928 19.5397 11.9385C19.896 12.0177 20.2126 12.0177 20.6084 11.9385C20.6084 10.7511 20.4501 9.60331 20.173 8.49508C19.0648 3.5872 15.7005 0.816621 10.8322 0.97494C8.73448 1.01452 6.87423 1.68737 5.25146 3.11224C5.44936 3.42888 5.64726 3.70594 5.84516 4.02258C6.43886 3.46846 7.07213 3.03308 7.78456 2.71645Z"
              fill="#FB3638"
            />
            <path
              d="M14.1571 9.56404C13.6822 10.1577 13.6822 10.8306 13.9197 11.543C13.9988 11.8201 14.1571 11.9388 14.4738 11.9388C15.5424 11.8992 16.6111 11.9388 17.6797 11.9388C17.7985 11.9388 17.9568 11.9784 18.0755 11.8201C18.4317 11.2264 18.4713 10.5931 18.2338 9.95984C17.9964 9.36615 17.5214 9.04951 16.8486 9.08909C16.6507 9.08909 16.4528 9.28699 16.2549 9.12867C16.1757 8.69329 16.5715 8.41623 16.6111 8.06002C16.2944 7.98086 16.2549 8.25791 16.057 8.29749C15.4237 7.46632 15.0675 7.38716 14.355 7.82254C14.6321 8.61413 15.2258 8.73287 15.9778 8.53497C15.9382 8.81203 15.9778 9.00993 15.7799 9.20783C15.1466 8.97035 14.5925 9.04951 14.1571 9.56404Z"
              fill="#FB3638"
            />
            <path
              d="M1.84766 11.9388C2.20387 11.9388 2.52051 11.9388 2.87673 11.9388C2.95589 10.7118 3.39126 9.44527 2.24345 8.49536C2.00598 9.64317 1.84766 10.791 1.84766 11.9388Z"
              fill="#FB3638"
            />
            <path
              d="M4.34121 3.31032C4.30163 3.27074 4.34121 3.27074 4.34121 3.31032C4.30163 3.27074 4.30163 3.27074 4.34121 3.31032Z"
              fill="#FB3638"
            />
            <path
              d="M4.42047 3.35013C4.38089 3.35013 4.38089 3.31055 4.34131 3.31055C4.34131 3.35013 4.34131 3.35013 4.34131 3.38971C4.34131 3.38971 4.38089 3.35013 4.42047 3.35013Z"
              fill="#FB3638"
            />
            <path
              d="M9.64474 11.9783L13.0882 11.9388C13.009 11.6221 12.9694 11.3847 12.9299 11.1472C12.8903 10.5931 12.6528 10.316 12.0591 10.1973C11.1488 10.0389 10.5551 9.36609 10.1989 8.45576C11.1488 8.41618 11.8216 8.85155 12.4549 9.44525C12.3757 8.97029 12.257 8.53491 12.1778 8.05996C12.0987 7.62458 11.9404 7.34752 11.4258 7.22878C10.4759 7.03089 9.9218 6.35803 9.48643 5.40812C10.3572 5.40812 11.03 5.76433 11.7029 6.35803C11.3467 5.28938 11.0696 4.37905 10.4363 3.66661C10.1593 3.31039 9.76348 3.07292 9.28853 3.19165C8.77399 3.31039 8.53651 3.70619 8.41777 4.18115C8.29903 4.69568 8.29903 5.21022 8.33861 5.72475C8.41777 7.3871 8.77399 8.97029 9.13021 10.5535C9.24895 11.068 9.32811 11.3847 9.44684 11.5826L9.64474 11.9783Z"
              fill="#FB3638"
            />
          </svg>
        </div>
        <div className="right-content">
          <h3 className="title">{statistics[1]?.title}</h3>
          <p className="des">{statistics[1]?.description}</p>
        </div>
      </div>
      <div className="total-card">
        <div className="left-icon purple">
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.93722 19.848C4.26036 19.848 3.58349 19.848 2.88672 19.848C2.88672 17.3197 2.88672 14.7914 2.88672 12.2432C3.50386 12.3626 4.08119 12.3029 4.6386 11.9645C4.73814 11.9047 4.81778 11.9844 4.89741 12.0242C5.61409 12.4024 6.31086 12.3825 7.02754 12.0242C7.1669 11.9446 7.26644 11.9246 7.4257 12.0043C8.12247 12.3825 8.81925 12.3626 9.51602 12.0242C9.69519 11.9446 9.81464 11.9446 9.9739 12.0242C10.6508 12.3626 11.3475 12.3626 12.0244 12.0242C12.1837 11.9446 12.2832 11.9247 12.4624 12.0242C13.1592 12.3825 13.8559 12.3825 14.5527 12.0242C14.712 11.9446 14.8115 11.9446 14.9708 12.0242C15.6476 12.3825 16.3444 12.3626 17.0213 12.0441C17.1805 11.9645 17.3 11.9446 17.4791 12.0242C18.156 12.3825 18.8528 12.3626 19.5297 12.0242C19.7088 11.9446 19.8283 11.9247 19.9875 12.0242C20.4653 12.283 20.9829 12.3228 21.5603 12.2034C21.5603 14.7516 21.5603 17.2799 21.5603 19.8281C18.0565 19.8281 14.5726 19.8281 11.0688 19.8281C10.9693 19.6887 11.0091 19.5493 11.0091 19.41C11.0091 18.0364 11.0091 16.6627 11.0091 15.2891C11.0091 14.3932 10.6508 14.0548 9.75492 14.0548C8.56045 14.0548 7.34607 14.0548 6.1516 14.0548C5.3752 14.0548 4.99695 14.4131 4.99695 15.1895C4.99695 16.5831 4.99695 17.9965 4.99695 19.3901C4.99695 19.5493 5.01685 19.6887 4.93722 19.848ZM16.006 18.7331C16.7227 18.7331 17.4592 18.7331 18.1759 18.7331C18.5144 18.7331 18.6537 18.5938 18.6537 18.2553C18.6537 16.8021 18.6537 15.3488 18.6537 13.8955C18.6537 13.5571 18.5144 13.4177 18.1759 13.4177C16.7227 13.4177 15.2694 13.4177 13.8161 13.4177C13.4777 13.4177 13.3383 13.5571 13.3383 13.8955C13.3383 15.3488 13.3383 16.8021 13.3383 18.2553C13.3383 18.5938 13.4777 18.7331 13.8161 18.7331C14.5527 18.7331 15.2694 18.7331 16.006 18.7331Z"
              fill="#CF3CEC"
            />
            <path
              d="M12.2234 6.1316C15.8864 6.1316 19.5495 6.1316 23.2125 6.1316C24.1283 6.1316 24.4468 6.45012 24.4468 7.36588C24.4468 7.70432 24.4468 8.02284 24.4468 8.36128C24.4269 8.9386 24.0885 9.29694 23.531 9.35667C23.3917 9.37657 23.2722 9.35667 23.1329 9.35667C15.8665 9.35667 8.60018 9.35667 1.31391 9.35667C0.298616 9.35667 0 9.03814 0 8.02284C0 7.70432 0 7.38579 0 7.06727C0.0199078 6.50985 0.398154 6.1316 0.955573 6.11169C1.07502 6.11169 1.19447 6.11169 1.31391 6.11169C4.95705 6.1316 8.58027 6.1316 12.2234 6.1316Z"
              fill="#CF3CEC"
            />
            <path
              d="M12.2033 23.6904C8.77913 23.6904 5.35499 23.6904 1.93084 23.6904C1.37343 23.6904 1.35352 23.6705 1.35352 23.1131C1.35352 22.5756 1.35352 22.0381 1.35352 21.4807C1.35352 20.8635 1.71186 20.5052 2.329 20.4654C2.42854 20.4654 2.54799 20.4654 2.64752 20.4654C9.03794 20.4654 15.4084 20.4654 21.7989 20.4654C22.0577 20.4654 22.2965 20.4454 22.5354 20.5649C22.8938 20.7441 23.0929 21.0427 23.0929 21.4607C23.0929 22.0381 23.0929 22.5955 23.0929 23.1728C23.0929 23.6705 23.053 23.7103 22.5553 23.7103C19.0914 23.6904 15.6473 23.6904 12.2033 23.6904Z"
              fill="#CF3CEC"
            />
            <path
              d="M18.5541 5.55428C14.3138 5.55428 10.1132 5.55428 5.93256 5.55428C5.81312 5.43483 5.87284 5.29548 5.87284 5.17603C5.87284 3.92184 5.87284 2.66765 5.87284 1.41346C5.87284 0.497697 6.35063 0.0199078 7.26639 0C10.5711 0 13.8758 0 17.1805 0C18.0763 0 18.574 0.497696 18.574 1.39355C18.574 2.64774 18.574 3.90193 18.574 5.15613C18.5541 5.29548 18.5541 5.41493 18.5541 5.55428ZM12.2234 4.3399C13.617 4.3399 14.9906 4.3399 16.3842 4.3399C16.9018 4.3399 17.1208 4.10101 17.1407 3.58341C17.1407 3.0459 17.1407 2.50838 17.1407 1.95096C17.1407 1.45327 16.9018 1.21438 16.424 1.21438C13.6369 1.21438 10.8498 1.21438 8.0627 1.21438C7.56501 1.21438 7.34602 1.45327 7.32611 1.95096C7.32611 2.46857 7.32611 2.98617 7.32611 3.50378C7.32611 4.14083 7.52519 4.3399 8.14233 4.3399C9.49606 4.3399 10.8697 4.3399 12.2234 4.3399Z"
              fill="#CF3CEC"
            />
            <path
              d="M22.5356 9.97388C22.5953 10.6906 22.3763 11.248 21.7393 11.5466C21.0027 11.885 20.3258 11.7457 19.7485 11.1683C18.8327 11.9248 18.0763 11.9049 17.2401 11.1683C16.4836 11.9049 15.4683 11.9049 14.7118 11.1683C13.8956 11.9248 13.159 11.9248 12.2233 11.1883C11.2877 11.9248 10.5511 11.9248 9.73487 11.1683C9.03809 11.885 8.00289 11.9647 7.20658 11.1683C6.39035 11.9248 5.63386 11.9248 4.69819 11.1683C4.12086 11.7457 3.444 11.885 2.70741 11.5466C2.13008 11.2878 1.89119 10.7503 1.931 9.97388C8.77929 9.97388 15.6475 9.97388 22.5356 9.97388Z"
              fill="#CF3CEC"
            />
            <path
              d="M10.412 19.868C9.6953 19.868 8.99853 19.868 8.30176 19.868C8.30176 18.136 8.30176 16.424 8.30176 14.6522C8.87908 14.6522 9.45641 14.6323 10.0337 14.6522C10.2925 14.6522 10.4319 14.8512 10.4319 15.11C10.412 16.6828 10.412 18.2356 10.412 19.868ZM9.37678 17.5786C9.37678 17.3994 9.37678 17.2004 9.37678 17.0212C9.37678 16.842 9.29715 16.7027 9.09807 16.7027C8.9189 16.7027 8.79945 16.8221 8.79945 17.0013C8.79945 17.3795 8.79945 17.7379 8.79945 18.1161C8.79945 18.2953 8.9189 18.3948 9.05826 18.3948C9.23743 18.3948 9.33697 18.2754 9.35687 18.0962C9.37678 17.917 9.37678 17.7578 9.37678 17.5786Z"
              fill="#CF3CEC"
            />
            <path
              d="M7.68434 14.6321C7.68434 16.4039 7.68434 18.116 7.68434 19.8479C6.98756 19.8479 6.29079 19.8479 5.5542 19.8479C5.5542 19.4498 5.5542 19.0516 5.5542 18.6535C5.5542 17.5386 5.5542 16.4039 5.5542 15.289C5.5542 14.7316 5.65374 14.6321 6.23106 14.6321C6.70885 14.6321 7.18664 14.6321 7.68434 14.6321ZM7.14683 17.5386C7.14683 17.3595 7.14683 17.1604 7.14683 16.9812C7.14683 16.802 7.04729 16.7025 6.88802 16.6826C6.72876 16.6627 6.60931 16.7821 6.58941 16.9414C6.5695 17.3395 6.5695 17.7377 6.58941 18.1359C6.58941 18.2951 6.72876 18.3947 6.88802 18.3947C7.02738 18.3747 7.12692 18.2951 7.14683 18.1359C7.16673 17.9368 7.14683 17.7377 7.14683 17.5386Z"
              fill="#CF3CEC"
            />
            <path
              d="M18.0566 14.0548C18.0566 14.6321 18.0566 15.2095 18.0566 15.7868C17.4593 15.7868 16.9019 15.7868 16.3047 15.7868C16.3047 15.2095 16.3047 14.652 16.3047 14.0548C16.8621 14.0548 17.4394 14.0548 18.0566 14.0548Z"
              fill="#CF3CEC"
            />
            <path
              d="M16.3047 16.4041C16.882 16.4041 17.4394 16.4041 18.0367 16.4041C18.0367 16.9814 18.0367 17.5388 18.0367 18.1559C17.4593 18.1559 16.9019 18.1559 16.3047 18.1559C16.3047 17.5587 16.3047 17.0013 16.3047 16.4041Z"
              fill="#CF3CEC"
            />
            <path
              d="M15.6875 14.035C15.6875 14.6323 15.6875 15.2096 15.6875 15.7869C15.1102 15.7869 14.5528 15.7869 13.9556 15.7869C13.9556 15.2096 13.9556 14.6323 13.9556 14.035C14.5329 14.035 15.1102 14.035 15.6875 14.035Z"
              fill="#CF3CEC"
            />
            <path
              d="M15.6875 16.4238C15.6875 16.9812 15.6875 17.5387 15.6875 18.1359C15.1102 18.1359 14.5329 18.1359 13.9556 18.1359C13.9556 17.5586 13.9556 17.0211 13.9556 16.4238C14.513 16.4238 15.0903 16.4238 15.6875 16.4238Z"
              fill="#CF3CEC"
            />
          </svg>
        </div>
        <div className="right-content">
          <h3 className="title">{statistics[2]?.title}</h3>
          <p className="des">{statistics[2]?.description}</p>
        </div>
      </div>
      <div className="total-card">
        <div className="left-icon info">
          <svg
            width="20"
            height="25"
            viewBox="0 0 20 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.00756056 23.7399C0.173895 23.3179 0.196577 22.8733 0.287305 22.4361C0.400716 21.8784 0.499004 21.3057 0.597293 20.748C0.854356 19.3613 1.67847 18.3815 2.83525 17.6429C3.05451 17.4997 3.28133 17.3716 3.5762 17.2661C3.51571 19.6552 3.46279 22.0292 3.4023 24.4258C2.79745 24.4258 2.20772 24.4258 1.61042 24.4258C1.27019 24.4258 0.929963 24.4107 0.589732 24.4333C0.325109 24.4484 0.11341 24.4107 0 24.1469C0.00756067 24.0037 0.00756056 23.8681 0.00756056 23.7399Z"
              fill="#4BB9C5"
            />
            <path
              d="M14.1235 6.83564C14.1235 7.90582 14.1537 8.94585 14.1159 9.97835C14.063 11.561 13.4884 12.9402 12.2787 13.9953C10.1088 15.8869 6.91059 15.2916 5.45894 12.7442C4.92969 11.8172 4.67263 10.8149 4.65751 9.75225C4.64995 8.79512 4.65751 7.83045 4.65751 6.84317C4.3702 6.84317 4.09046 6.84317 3.81071 6.84317C3.10001 6.84317 2.39687 6.84317 1.68616 6.84317C1.30813 6.84317 1.14936 6.68491 1.14936 6.31562C1.14936 5.82575 1.1418 5.33588 1.14936 4.84601C1.16448 3.81351 2.01127 2.95435 3.04708 2.94681C7.25838 2.93928 11.4772 2.93928 15.6885 2.94681C16.747 2.94681 17.5711 3.77583 17.5863 4.83093C17.5938 5.33588 17.5863 5.84836 17.5863 6.3533C17.5863 6.71505 17.4728 6.83564 17.0948 6.83564C16.2102 6.83564 15.3181 6.83564 14.4335 6.83564C14.3352 6.83564 14.2444 6.83564 14.1235 6.83564Z"
              fill="#4BB9C5"
            />
            <path
              d="M4.25684 24.4031C4.29464 22.4964 4.33244 20.6123 4.37781 18.7056C5.95799 20.3184 7.954 20.5746 10.0332 20.4842C11.0917 20.439 12.1275 20.2129 13.0877 19.7305C13.8362 19.3537 14.4184 18.8111 14.8115 18.0424C14.7586 20.1601 14.6981 22.2703 14.6452 24.4031C11.1748 24.4031 7.73474 24.4031 4.25684 24.4031Z"
              fill="#4BB9C5"
            />
            <path
              d="M5.85211 16.7687C6.07137 16.2035 6.29819 15.6307 6.52501 15.0428C8.43786 16.1809 10.328 16.1432 12.2484 15.0428C12.4753 15.6156 12.7021 16.1884 12.9365 16.7913C13.1633 16.7913 13.3977 16.7838 13.632 16.7913C13.9572 16.7988 14.1386 16.9873 14.1235 17.3113C14.0857 17.8916 13.7681 18.3137 13.3296 18.6528C12.8004 19.0673 12.188 19.316 11.5453 19.4668C9.83658 19.8662 8.14299 19.8511 6.47965 19.2181C5.97308 19.0221 5.51188 18.7433 5.14897 18.3363C4.88434 18.0273 4.71045 17.6806 4.71045 17.2661C4.71045 16.9496 4.86166 16.8064 5.17921 16.7988C5.38335 16.7913 5.59505 16.7913 5.79919 16.7913C5.81431 16.7762 5.82187 16.7762 5.85211 16.7687Z"
              fill="#4BB9C5"
            />
            <path
              d="M15.5975 17.2285C17.3743 18.0048 18.5613 19.1805 18.8335 21.1475C18.9545 22.0368 19.1511 22.9261 19.3174 23.8079C19.4006 24.245 19.2645 24.4108 18.8335 24.4108C17.7297 24.4108 16.6258 24.4108 15.4917 24.4108C15.5219 22.0293 15.5597 19.6477 15.5975 17.2285Z"
              fill="#4BB9C5"
            />
            <path
              d="M6.41162 2.14036C6.63844 1.50729 6.85014 0.904377 7.06184 0.308995C7.13745 0.0979735 7.28866 0 7.51548 0C8.77055 0 10.0256 0 11.2807 0C11.4546 0 11.6058 0.0452184 11.6738 0.218557C11.9234 0.851621 12.1653 1.49222 12.4148 2.14036C10.4112 2.14036 8.43032 2.14036 6.41162 2.14036Z"
              fill="#4BB9C5"
            />
          </svg>
        </div>
        <div className="right-content">
          <h3 className="title">{statistics[3]?.title}</h3>
          <p className="des">{statistics[3]?.description}</p>
        </div>
      </div>
    </div>
  </section>
  )
}

export default TotalDetail