import React from "react";
import Hero from "src/components/Langing/Hero";
import Header from "src/components/Langing/Header";
import TotalDetail from "src/components/Langing/TotalDetail";
import HowWorks from "src/components/Langing/HowWorks";
import WhoWe from "src/components/Langing/WhoWe";
import WhChoose from "src/components/Langing/WhChoose";
import Cta from "src/components/Langing/Cta";
import Testimonial from "src/components/home/Testimonial";
import FaqSection from "src/components/Langing/Faq";
import Blogs from "src/components/Langing/Blogs";
import Footer from "src/components/Langing/Footer";
import HowIt from "src/components/Krishijotno/HowIt";
import Head from "next/head";

function landing() {
  return (
    <div className="landing">
      <Head>
        <title>AgriCare Global</title>
        <meta
          name="description"
          content="Building Nation Through Sustainable Agri Finance Solutions"
        />
      </Head>
      <Header />
      <Hero />
      <TotalDetail />
      <div className="gap"></div>
      <HowWorks />
      <WhoWe />
      <HowIt />
      <WhChoose />
      <Cta />
      <div className="gap"></div>
      {/* <Testimonial/> */}
      <Blogs />
      {/* <FaqSection/> */}
      <Footer />
      {/* <div className="gap"></div> */}
    </div>
  );
}

export default landing;
