"use client";
import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  useMediaQuery,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import ChatIcon from "@mui/icons-material/Chat";
import BookIcon from "@mui/icons-material/Book";
import MailIcon from "@mui/icons-material/Mail";
import Link from "next/link";

const Header = () => {
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = React.useState(false);
  const [activeMenuItem, setActiveMenuItem] = React.useState("");
  const isMobileDevice = useMediaQuery("(max-width: 767px)");

  const handleMobileDrawerToggle = (id) => {
    setIsMobileDrawerOpen(false);
    setActiveMenuItem(id);
  };

  const menuItems = [
    { id: "home", text: "Home", icon: <HomeIcon />, link: "/" },
    { id: "krishi-jotno", text: " কৃষি যত্ন ", icon: <HomeIcon />, link: "/krishi-jotno" },
    { id: "about", text: "About", icon: <InfoIcon />, link: "/#about" },
    { id: "landing-blog", text: "Blog", icon: <BookIcon />, link: "/#landing-blog" },
    { id: "login", text: "Login", icon: <BookIcon />, link: "/more/login" },
    { id: "project", text: "Project", icon: <BookIcon />, link: "/app/#project" },
    { id: "contact", text: "Contact", icon: <MailIcon />, link: "/#contact" },
  ];

  React.useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const sectionElements = Array.from(document.querySelectorAll("section[id]"));

      const activeSection = sectionElements.find((section) => {
        const sectionTop = section.offsetTop - 200; // Adjust the offset as needed
        const sectionBottom = sectionTop + section.offsetHeight;
        return scrollY >= sectionTop && scrollY < sectionBottom;
      });

      setActiveMenuItem(activeSection?.id || "");
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <header className="landing-header">
        <div className="container">
          <div className="header-wrapper">
            {isMobileDevice && (
              <IconButton
                color="#555"
                aria-label="open drawer"
                edge="start"
                onClick={() => setIsMobileDrawerOpen(!isMobileDrawerOpen)}
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <div className="logo">
              <Link href="/">
                <img src="public/images/logo/new-logo/logo-small.png" alt="Logo" width="160" />
              </Link>
            </div>
            <nav className="desk-menu">
              <ul className="deskList">
                {menuItems.map((item) => (
                  <li
                    key={item.id}
                    onClick={() => setActiveMenuItem(item.id)}
                    className={`deskList-list ${activeMenuItem === item.id ? "active" : ""}`}
                  >
                    <Link href={item.link} className="deskList-link">
                      {item.text}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </header>
      <section className="landing-gap"></section>
      {isMobileDevice ? (
        <Drawer
          anchor="left"
          open={isMobileDrawerOpen}
          onClose={() => setIsMobileDrawerOpen(false)}
        >
          <List>
            {menuItems.map((item) => (
              <ListItemButton
                key={item.id}
                component="a"
                href={item.link}
                onClick={() => handleMobileDrawerToggle(item.id)}
              >
                <ListItemText primary={item.text} />
              </ListItemButton>
            ))}
          </List>
        </Drawer>
      ) : null}
    </>
  );
};

export default Header;
