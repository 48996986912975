import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation,Autoplay } from "swiper/modules";

export default function Testimonial() {
  
  return (
    <>
    <section className="testimonial-section" id="landing-testimonial">
      
      <div className="container">
        <div class="section-top">
          <h1 class="section-title">Testimonial</h1>
          <p class="section-des">Get Your Desired Product from Featured Category!</p>
        </div>
        <div className="swipper-container">
        <Swiper
        pagination={{ type: "fraction" }}
        navigation={true}
        modules={[Autoplay,Pagination, Navigation]}
        className="mySwiper"
        slidesPerView={1}
        spaceBetween={20}
        breakpoints={{
          // when window width is less than 640px (mobile)
          992: {
            slidesPerView: 3,
          },
        }} 
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
      >
        <SwiperSlide>
          <div className="box">
            <div className="testimonialBox-top">
              <span>
                  <svg width="42" height="34" viewBox="0 0 42 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7141 0C8.46001 0 3.05478 5.68824 0.86372 13.5241C-0.261658 17.5506 -0.288788 21.9398 0.791088 25.5668C1.98743 29.5871 4.44981 32.3062 7.72493 33.2216C8.54934 33.4524 9.39838 33.5697 10.2487 33.5697C14.4229 33.5697 18.1242 30.7588 19.2496 26.7352C20.638 21.7695 17.7285 16.5993 12.762 15.2097C10.5605 14.5965 8.12941 14.8298 6.09155 15.8383C6.15708 15.5637 6.22512 15.3003 6.29692 15.0427C8.1123 8.5522 11.6733 5.63189 13.6219 5.63189L13.7079 5.63356C14.0823 5.65068 14.4425 5.39814 14.5469 5.02454L15.6284 1.15585C15.6923 0.92877 15.6568 0.685829 15.5312 0.485882C15.4051 0.287188 15.2014 0.150273 14.9685 0.109365C14.5577 0.0367334 14.1362 0 13.7141 0ZM36.6271 0.109969C36.2168 0.0373373 35.7947 0.00143881 35.3736 0.00143881C30.1182 0.00143881 24.7138 5.68885 22.5215 13.5239C21.3957 17.5508 21.369 21.9404 22.4484 25.5674C23.6452 29.5877 26.1076 32.3068 29.3823 33.2222C30.2071 33.453 31.0561 33.5703 31.9064 33.5703C36.0807 33.5703 39.782 30.7594 40.9074 26.7358C42.2966 21.7701 39.3863 16.5999 34.4206 15.2103C32.2191 14.5971 29.7888 14.8304 27.7501 15.8389C27.8153 15.5659 27.8833 15.3026 27.9555 15.0433C29.7709 8.5528 33.3324 5.6325 35.2809 5.6325L35.3661 5.63417C35.7472 5.65128 36.1007 5.39874 36.2051 5.02514L37.287 1.15645C37.3505 0.929374 37.315 0.686432 37.1894 0.486486C37.0637 0.287792 36.8596 0.150042 36.6271 0.109969Z" fill="#FF5660"/>
                  </svg>
              </span>
              <p className="title">Amazing service. You guys know how to do business. </p>
            </div>
            <div className="testimonialBox-middle">
            you were awesome in processing my order on priority and Im really impressed with how you manage and notify your customers. The laptop was also of good quality and I got a good deal too.
            </div>
            <div className="testimonialBox-footer">
              <figure className="testimonial-thumb">
                <img src="public/images/agro.jpg" alt="" />
              </figure>
              <div className="content">
                <h3 className="name">Kamal Uddin</h3>
                <p className="title">Farmer</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
       
        <SwiperSlide>
          <div className="box">
            <div className="testimonialBox-top">
              <span>
                  <svg width="42" height="34" viewBox="0 0 42 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7141 0C8.46001 0 3.05478 5.68824 0.86372 13.5241C-0.261658 17.5506 -0.288788 21.9398 0.791088 25.5668C1.98743 29.5871 4.44981 32.3062 7.72493 33.2216C8.54934 33.4524 9.39838 33.5697 10.2487 33.5697C14.4229 33.5697 18.1242 30.7588 19.2496 26.7352C20.638 21.7695 17.7285 16.5993 12.762 15.2097C10.5605 14.5965 8.12941 14.8298 6.09155 15.8383C6.15708 15.5637 6.22512 15.3003 6.29692 15.0427C8.1123 8.5522 11.6733 5.63189 13.6219 5.63189L13.7079 5.63356C14.0823 5.65068 14.4425 5.39814 14.5469 5.02454L15.6284 1.15585C15.6923 0.92877 15.6568 0.685829 15.5312 0.485882C15.4051 0.287188 15.2014 0.150273 14.9685 0.109365C14.5577 0.0367334 14.1362 0 13.7141 0ZM36.6271 0.109969C36.2168 0.0373373 35.7947 0.00143881 35.3736 0.00143881C30.1182 0.00143881 24.7138 5.68885 22.5215 13.5239C21.3957 17.5508 21.369 21.9404 22.4484 25.5674C23.6452 29.5877 26.1076 32.3068 29.3823 33.2222C30.2071 33.453 31.0561 33.5703 31.9064 33.5703C36.0807 33.5703 39.782 30.7594 40.9074 26.7358C42.2966 21.7701 39.3863 16.5999 34.4206 15.2103C32.2191 14.5971 29.7888 14.8304 27.7501 15.8389C27.8153 15.5659 27.8833 15.3026 27.9555 15.0433C29.7709 8.5528 33.3324 5.6325 35.2809 5.6325L35.3661 5.63417C35.7472 5.65128 36.1007 5.39874 36.2051 5.02514L37.287 1.15645C37.3505 0.929374 37.315 0.686432 37.1894 0.486486C37.0637 0.287792 36.8596 0.150042 36.6271 0.109969Z" fill="#FF5660"/>
                  </svg>
              </span>
              <p className="title">Amazing service. You guys know how to do business. </p>
            </div>
            <div className="testimonialBox-middle">
            you were awesome in processing my order on priority and Im really impressed with how you manage and notify your customers. The laptop was also of good quality and I got a good deal too.
            </div>
            <div className="testimonialBox-footer">
              <figure className="testimonial-thumb">
                <img src="public/images/agro.jpg" alt="" />
              </figure>
              <div className="content">
                <h3 className="name">Kamal Uddin</h3>
                <p className="title">Farmer</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
       
        <SwiperSlide>
          <div className="box">
            <div className="testimonialBox-top">
              <span>
                  <svg width="42" height="34" viewBox="0 0 42 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7141 0C8.46001 0 3.05478 5.68824 0.86372 13.5241C-0.261658 17.5506 -0.288788 21.9398 0.791088 25.5668C1.98743 29.5871 4.44981 32.3062 7.72493 33.2216C8.54934 33.4524 9.39838 33.5697 10.2487 33.5697C14.4229 33.5697 18.1242 30.7588 19.2496 26.7352C20.638 21.7695 17.7285 16.5993 12.762 15.2097C10.5605 14.5965 8.12941 14.8298 6.09155 15.8383C6.15708 15.5637 6.22512 15.3003 6.29692 15.0427C8.1123 8.5522 11.6733 5.63189 13.6219 5.63189L13.7079 5.63356C14.0823 5.65068 14.4425 5.39814 14.5469 5.02454L15.6284 1.15585C15.6923 0.92877 15.6568 0.685829 15.5312 0.485882C15.4051 0.287188 15.2014 0.150273 14.9685 0.109365C14.5577 0.0367334 14.1362 0 13.7141 0ZM36.6271 0.109969C36.2168 0.0373373 35.7947 0.00143881 35.3736 0.00143881C30.1182 0.00143881 24.7138 5.68885 22.5215 13.5239C21.3957 17.5508 21.369 21.9404 22.4484 25.5674C23.6452 29.5877 26.1076 32.3068 29.3823 33.2222C30.2071 33.453 31.0561 33.5703 31.9064 33.5703C36.0807 33.5703 39.782 30.7594 40.9074 26.7358C42.2966 21.7701 39.3863 16.5999 34.4206 15.2103C32.2191 14.5971 29.7888 14.8304 27.7501 15.8389C27.8153 15.5659 27.8833 15.3026 27.9555 15.0433C29.7709 8.5528 33.3324 5.6325 35.2809 5.6325L35.3661 5.63417C35.7472 5.65128 36.1007 5.39874 36.2051 5.02514L37.287 1.15645C37.3505 0.929374 37.315 0.686432 37.1894 0.486486C37.0637 0.287792 36.8596 0.150042 36.6271 0.109969Z" fill="#FF5660"/>
                  </svg>
              </span>
              <p className="title">Amazing service. You guys know how to do business. </p>
            </div>
            <div className="testimonialBox-middle">
            you were awesome in processing my order on priority and Im really impressed with how you manage and notify your customers. The laptop was also of good quality and I got a good deal too.
            </div>
            <div className="testimonialBox-footer">
              <figure className="testimonial-thumb">
                <img src="public/images/agro.jpg" alt="" />
              </figure>
              <div className="content">
                <h3 className="name">Kamal Uddin</h3>
                <p className="title">Farmer</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
       
        <SwiperSlide>
          <div className="box">
            <div className="testimonialBox-top">
              <span>
                  <svg width="42" height="34" viewBox="0 0 42 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7141 0C8.46001 0 3.05478 5.68824 0.86372 13.5241C-0.261658 17.5506 -0.288788 21.9398 0.791088 25.5668C1.98743 29.5871 4.44981 32.3062 7.72493 33.2216C8.54934 33.4524 9.39838 33.5697 10.2487 33.5697C14.4229 33.5697 18.1242 30.7588 19.2496 26.7352C20.638 21.7695 17.7285 16.5993 12.762 15.2097C10.5605 14.5965 8.12941 14.8298 6.09155 15.8383C6.15708 15.5637 6.22512 15.3003 6.29692 15.0427C8.1123 8.5522 11.6733 5.63189 13.6219 5.63189L13.7079 5.63356C14.0823 5.65068 14.4425 5.39814 14.5469 5.02454L15.6284 1.15585C15.6923 0.92877 15.6568 0.685829 15.5312 0.485882C15.4051 0.287188 15.2014 0.150273 14.9685 0.109365C14.5577 0.0367334 14.1362 0 13.7141 0ZM36.6271 0.109969C36.2168 0.0373373 35.7947 0.00143881 35.3736 0.00143881C30.1182 0.00143881 24.7138 5.68885 22.5215 13.5239C21.3957 17.5508 21.369 21.9404 22.4484 25.5674C23.6452 29.5877 26.1076 32.3068 29.3823 33.2222C30.2071 33.453 31.0561 33.5703 31.9064 33.5703C36.0807 33.5703 39.782 30.7594 40.9074 26.7358C42.2966 21.7701 39.3863 16.5999 34.4206 15.2103C32.2191 14.5971 29.7888 14.8304 27.7501 15.8389C27.8153 15.5659 27.8833 15.3026 27.9555 15.0433C29.7709 8.5528 33.3324 5.6325 35.2809 5.6325L35.3661 5.63417C35.7472 5.65128 36.1007 5.39874 36.2051 5.02514L37.287 1.15645C37.3505 0.929374 37.315 0.686432 37.1894 0.486486C37.0637 0.287792 36.8596 0.150042 36.6271 0.109969Z" fill="#FF5660"/>
                  </svg>
              </span>
              <p className="title">Amazing service. You guys know how to do business. </p>
            </div>
            <div className="testimonialBox-middle">
            you were awesome in processing my order on priority and Im really impressed with how you manage and notify your customers. The laptop was also of good quality and I got a good deal too.
            </div>
            <div className="testimonialBox-footer">
              <figure className="testimonial-thumb">
                <img src="public/images/agro.jpg" alt="" />
              </figure>
              <div className="content">
                <h3 className="name">Kamal Uddin</h3>
                <p className="title">Farmer</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
       
        <SwiperSlide>
          <div className="box">
            <div className="testimonialBox-top">
              <span>
                  <svg width="42" height="34" viewBox="0 0 42 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7141 0C8.46001 0 3.05478 5.68824 0.86372 13.5241C-0.261658 17.5506 -0.288788 21.9398 0.791088 25.5668C1.98743 29.5871 4.44981 32.3062 7.72493 33.2216C8.54934 33.4524 9.39838 33.5697 10.2487 33.5697C14.4229 33.5697 18.1242 30.7588 19.2496 26.7352C20.638 21.7695 17.7285 16.5993 12.762 15.2097C10.5605 14.5965 8.12941 14.8298 6.09155 15.8383C6.15708 15.5637 6.22512 15.3003 6.29692 15.0427C8.1123 8.5522 11.6733 5.63189 13.6219 5.63189L13.7079 5.63356C14.0823 5.65068 14.4425 5.39814 14.5469 5.02454L15.6284 1.15585C15.6923 0.92877 15.6568 0.685829 15.5312 0.485882C15.4051 0.287188 15.2014 0.150273 14.9685 0.109365C14.5577 0.0367334 14.1362 0 13.7141 0ZM36.6271 0.109969C36.2168 0.0373373 35.7947 0.00143881 35.3736 0.00143881C30.1182 0.00143881 24.7138 5.68885 22.5215 13.5239C21.3957 17.5508 21.369 21.9404 22.4484 25.5674C23.6452 29.5877 26.1076 32.3068 29.3823 33.2222C30.2071 33.453 31.0561 33.5703 31.9064 33.5703C36.0807 33.5703 39.782 30.7594 40.9074 26.7358C42.2966 21.7701 39.3863 16.5999 34.4206 15.2103C32.2191 14.5971 29.7888 14.8304 27.7501 15.8389C27.8153 15.5659 27.8833 15.3026 27.9555 15.0433C29.7709 8.5528 33.3324 5.6325 35.2809 5.6325L35.3661 5.63417C35.7472 5.65128 36.1007 5.39874 36.2051 5.02514L37.287 1.15645C37.3505 0.929374 37.315 0.686432 37.1894 0.486486C37.0637 0.287792 36.8596 0.150042 36.6271 0.109969Z" fill="#FF5660"/>
                  </svg>
              </span>
              <p className="title">Amazing service. You guys know how to do business. </p>
            </div>
            <div className="testimonialBox-middle">
            you were awesome in processing my order on priority and Im really impressed with how you manage and notify your customers. The laptop was also of good quality and I got a good deal too.
            </div>
            <div className="testimonialBox-footer">
              <figure className="testimonial-thumb">
                <img src="public/images/agro.jpg" alt="" />
              </figure>
              <div className="content">
                <h3 className="name">Kamal Uddin</h3>
                <p className="title">Farmer</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
       
        <SwiperSlide>
          <div className="box">
            <div className="testimonialBox-top">
              <span>
                  <svg width="42" height="34" viewBox="0 0 42 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7141 0C8.46001 0 3.05478 5.68824 0.86372 13.5241C-0.261658 17.5506 -0.288788 21.9398 0.791088 25.5668C1.98743 29.5871 4.44981 32.3062 7.72493 33.2216C8.54934 33.4524 9.39838 33.5697 10.2487 33.5697C14.4229 33.5697 18.1242 30.7588 19.2496 26.7352C20.638 21.7695 17.7285 16.5993 12.762 15.2097C10.5605 14.5965 8.12941 14.8298 6.09155 15.8383C6.15708 15.5637 6.22512 15.3003 6.29692 15.0427C8.1123 8.5522 11.6733 5.63189 13.6219 5.63189L13.7079 5.63356C14.0823 5.65068 14.4425 5.39814 14.5469 5.02454L15.6284 1.15585C15.6923 0.92877 15.6568 0.685829 15.5312 0.485882C15.4051 0.287188 15.2014 0.150273 14.9685 0.109365C14.5577 0.0367334 14.1362 0 13.7141 0ZM36.6271 0.109969C36.2168 0.0373373 35.7947 0.00143881 35.3736 0.00143881C30.1182 0.00143881 24.7138 5.68885 22.5215 13.5239C21.3957 17.5508 21.369 21.9404 22.4484 25.5674C23.6452 29.5877 26.1076 32.3068 29.3823 33.2222C30.2071 33.453 31.0561 33.5703 31.9064 33.5703C36.0807 33.5703 39.782 30.7594 40.9074 26.7358C42.2966 21.7701 39.3863 16.5999 34.4206 15.2103C32.2191 14.5971 29.7888 14.8304 27.7501 15.8389C27.8153 15.5659 27.8833 15.3026 27.9555 15.0433C29.7709 8.5528 33.3324 5.6325 35.2809 5.6325L35.3661 5.63417C35.7472 5.65128 36.1007 5.39874 36.2051 5.02514L37.287 1.15645C37.3505 0.929374 37.315 0.686432 37.1894 0.486486C37.0637 0.287792 36.8596 0.150042 36.6271 0.109969Z" fill="#FF5660"/>
                  </svg>
              </span>
              <p className="title">Amazing service. You guys know how to do business. </p>
            </div>
            <div className="testimonialBox-middle">
            you were awesome in processing my order on priority and Im really impressed with how you manage and notify your customers. The laptop was also of good quality and I got a good deal too.
            </div>
            <div className="testimonialBox-footer">
              <figure className="testimonial-thumb">
                <img src="public/images/agro.jpg" alt="" />
              </figure>
              <div className="content">
                <h3 className="name">Kamal Uddin</h3>
                <p className="title">Farmer</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
       
        <SwiperSlide>
          <div className="box">
            <div className="testimonialBox-top">
              <span>
                  <svg width="42" height="34" viewBox="0 0 42 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7141 0C8.46001 0 3.05478 5.68824 0.86372 13.5241C-0.261658 17.5506 -0.288788 21.9398 0.791088 25.5668C1.98743 29.5871 4.44981 32.3062 7.72493 33.2216C8.54934 33.4524 9.39838 33.5697 10.2487 33.5697C14.4229 33.5697 18.1242 30.7588 19.2496 26.7352C20.638 21.7695 17.7285 16.5993 12.762 15.2097C10.5605 14.5965 8.12941 14.8298 6.09155 15.8383C6.15708 15.5637 6.22512 15.3003 6.29692 15.0427C8.1123 8.5522 11.6733 5.63189 13.6219 5.63189L13.7079 5.63356C14.0823 5.65068 14.4425 5.39814 14.5469 5.02454L15.6284 1.15585C15.6923 0.92877 15.6568 0.685829 15.5312 0.485882C15.4051 0.287188 15.2014 0.150273 14.9685 0.109365C14.5577 0.0367334 14.1362 0 13.7141 0ZM36.6271 0.109969C36.2168 0.0373373 35.7947 0.00143881 35.3736 0.00143881C30.1182 0.00143881 24.7138 5.68885 22.5215 13.5239C21.3957 17.5508 21.369 21.9404 22.4484 25.5674C23.6452 29.5877 26.1076 32.3068 29.3823 33.2222C30.2071 33.453 31.0561 33.5703 31.9064 33.5703C36.0807 33.5703 39.782 30.7594 40.9074 26.7358C42.2966 21.7701 39.3863 16.5999 34.4206 15.2103C32.2191 14.5971 29.7888 14.8304 27.7501 15.8389C27.8153 15.5659 27.8833 15.3026 27.9555 15.0433C29.7709 8.5528 33.3324 5.6325 35.2809 5.6325L35.3661 5.63417C35.7472 5.65128 36.1007 5.39874 36.2051 5.02514L37.287 1.15645C37.3505 0.929374 37.315 0.686432 37.1894 0.486486C37.0637 0.287792 36.8596 0.150042 36.6271 0.109969Z" fill="#FF5660"/>
                  </svg>
              </span>
              <p className="title">Amazing service. You guys know how to do business. </p>
            </div>
            <div className="testimonialBox-middle">
            you were awesome in processing my order on priority and Im really impressed with how you manage and notify your customers. The laptop was also of good quality and I got a good deal too.
            </div>
            <div className="testimonialBox-footer">
              <figure className="testimonial-thumb">
                <img src="public/images/agro.jpg" alt="" />
              </figure>
              <div className="content">
                <h3 className="name">Kamal Uddin</h3>
                <p className="title">Farmer</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
       
        <SwiperSlide>
          <div className="box">
            <div className="testimonialBox-top">
              <span>
                  <svg width="42" height="34" viewBox="0 0 42 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7141 0C8.46001 0 3.05478 5.68824 0.86372 13.5241C-0.261658 17.5506 -0.288788 21.9398 0.791088 25.5668C1.98743 29.5871 4.44981 32.3062 7.72493 33.2216C8.54934 33.4524 9.39838 33.5697 10.2487 33.5697C14.4229 33.5697 18.1242 30.7588 19.2496 26.7352C20.638 21.7695 17.7285 16.5993 12.762 15.2097C10.5605 14.5965 8.12941 14.8298 6.09155 15.8383C6.15708 15.5637 6.22512 15.3003 6.29692 15.0427C8.1123 8.5522 11.6733 5.63189 13.6219 5.63189L13.7079 5.63356C14.0823 5.65068 14.4425 5.39814 14.5469 5.02454L15.6284 1.15585C15.6923 0.92877 15.6568 0.685829 15.5312 0.485882C15.4051 0.287188 15.2014 0.150273 14.9685 0.109365C14.5577 0.0367334 14.1362 0 13.7141 0ZM36.6271 0.109969C36.2168 0.0373373 35.7947 0.00143881 35.3736 0.00143881C30.1182 0.00143881 24.7138 5.68885 22.5215 13.5239C21.3957 17.5508 21.369 21.9404 22.4484 25.5674C23.6452 29.5877 26.1076 32.3068 29.3823 33.2222C30.2071 33.453 31.0561 33.5703 31.9064 33.5703C36.0807 33.5703 39.782 30.7594 40.9074 26.7358C42.2966 21.7701 39.3863 16.5999 34.4206 15.2103C32.2191 14.5971 29.7888 14.8304 27.7501 15.8389C27.8153 15.5659 27.8833 15.3026 27.9555 15.0433C29.7709 8.5528 33.3324 5.6325 35.2809 5.6325L35.3661 5.63417C35.7472 5.65128 36.1007 5.39874 36.2051 5.02514L37.287 1.15645C37.3505 0.929374 37.315 0.686432 37.1894 0.486486C37.0637 0.287792 36.8596 0.150042 36.6271 0.109969Z" fill="#FF5660"/>
                  </svg>
              </span>
              <p className="title">Amazing service. You guys know how to do business. </p>
            </div>
            <div className="testimonialBox-middle">
            you were awesome in processing my order on priority and Im really impressed with how you manage and notify your customers. The laptop was also of good quality and I got a good deal too.
            </div>
            <div className="testimonialBox-footer">
              <figure className="testimonial-thumb">
                <img src="public/images/agro.jpg" alt="" />
              </figure>
              <div className="content">
                <h3 className="name">Kamal Uddin</h3>
                <p className="title">Farmer</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
       
        <SwiperSlide>
          <div className="box">
            <div className="testimonialBox-top">
              <span>
                  <svg width="42" height="34" viewBox="0 0 42 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7141 0C8.46001 0 3.05478 5.68824 0.86372 13.5241C-0.261658 17.5506 -0.288788 21.9398 0.791088 25.5668C1.98743 29.5871 4.44981 32.3062 7.72493 33.2216C8.54934 33.4524 9.39838 33.5697 10.2487 33.5697C14.4229 33.5697 18.1242 30.7588 19.2496 26.7352C20.638 21.7695 17.7285 16.5993 12.762 15.2097C10.5605 14.5965 8.12941 14.8298 6.09155 15.8383C6.15708 15.5637 6.22512 15.3003 6.29692 15.0427C8.1123 8.5522 11.6733 5.63189 13.6219 5.63189L13.7079 5.63356C14.0823 5.65068 14.4425 5.39814 14.5469 5.02454L15.6284 1.15585C15.6923 0.92877 15.6568 0.685829 15.5312 0.485882C15.4051 0.287188 15.2014 0.150273 14.9685 0.109365C14.5577 0.0367334 14.1362 0 13.7141 0ZM36.6271 0.109969C36.2168 0.0373373 35.7947 0.00143881 35.3736 0.00143881C30.1182 0.00143881 24.7138 5.68885 22.5215 13.5239C21.3957 17.5508 21.369 21.9404 22.4484 25.5674C23.6452 29.5877 26.1076 32.3068 29.3823 33.2222C30.2071 33.453 31.0561 33.5703 31.9064 33.5703C36.0807 33.5703 39.782 30.7594 40.9074 26.7358C42.2966 21.7701 39.3863 16.5999 34.4206 15.2103C32.2191 14.5971 29.7888 14.8304 27.7501 15.8389C27.8153 15.5659 27.8833 15.3026 27.9555 15.0433C29.7709 8.5528 33.3324 5.6325 35.2809 5.6325L35.3661 5.63417C35.7472 5.65128 36.1007 5.39874 36.2051 5.02514L37.287 1.15645C37.3505 0.929374 37.315 0.686432 37.1894 0.486486C37.0637 0.287792 36.8596 0.150042 36.6271 0.109969Z" fill="#FF5660"/>
                  </svg>
              </span>
              <p className="title">Amazing service. You guys know how to do business. </p>
            </div>
            <div className="testimonialBox-middle">
            you were awesome in processing my order on priority and Im really impressed with how you manage and notify your customers. The laptop was also of good quality and I got a good deal too.
            </div>
            <div className="testimonialBox-footer">
              <figure className="testimonial-thumb">
                <img src="public/images/agro.jpg" alt="" />
              </figure>
              <div className="content">
                <h3 className="name">Kamal Uddin</h3>
                <p className="title">Farmer</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
       
        <SwiperSlide>
          <div className="box">
            <div className="testimonialBox-top">
              <span>
                  <svg width="42" height="34" viewBox="0 0 42 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7141 0C8.46001 0 3.05478 5.68824 0.86372 13.5241C-0.261658 17.5506 -0.288788 21.9398 0.791088 25.5668C1.98743 29.5871 4.44981 32.3062 7.72493 33.2216C8.54934 33.4524 9.39838 33.5697 10.2487 33.5697C14.4229 33.5697 18.1242 30.7588 19.2496 26.7352C20.638 21.7695 17.7285 16.5993 12.762 15.2097C10.5605 14.5965 8.12941 14.8298 6.09155 15.8383C6.15708 15.5637 6.22512 15.3003 6.29692 15.0427C8.1123 8.5522 11.6733 5.63189 13.6219 5.63189L13.7079 5.63356C14.0823 5.65068 14.4425 5.39814 14.5469 5.02454L15.6284 1.15585C15.6923 0.92877 15.6568 0.685829 15.5312 0.485882C15.4051 0.287188 15.2014 0.150273 14.9685 0.109365C14.5577 0.0367334 14.1362 0 13.7141 0ZM36.6271 0.109969C36.2168 0.0373373 35.7947 0.00143881 35.3736 0.00143881C30.1182 0.00143881 24.7138 5.68885 22.5215 13.5239C21.3957 17.5508 21.369 21.9404 22.4484 25.5674C23.6452 29.5877 26.1076 32.3068 29.3823 33.2222C30.2071 33.453 31.0561 33.5703 31.9064 33.5703C36.0807 33.5703 39.782 30.7594 40.9074 26.7358C42.2966 21.7701 39.3863 16.5999 34.4206 15.2103C32.2191 14.5971 29.7888 14.8304 27.7501 15.8389C27.8153 15.5659 27.8833 15.3026 27.9555 15.0433C29.7709 8.5528 33.3324 5.6325 35.2809 5.6325L35.3661 5.63417C35.7472 5.65128 36.1007 5.39874 36.2051 5.02514L37.287 1.15645C37.3505 0.929374 37.315 0.686432 37.1894 0.486486C37.0637 0.287792 36.8596 0.150042 36.6271 0.109969Z" fill="#FF5660"/>
                  </svg>
              </span>
              <p className="title">Amazing service. You guys know how to do business. </p>
            </div>
            <div className="testimonialBox-middle">
            you were awesome in processing my order on priority and Im really impressed with how you manage and notify your customers. The laptop was also of good quality and I got a good deal too.
            </div>
            <div className="testimonialBox-footer">
              <figure className="testimonial-thumb">
                <img src="public/images/agro.jpg" alt="" />
              </figure>
              <div className="content">
                <h3 className="name">Kamal Uddin</h3>
                <p className="title">Farmer</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
       
      </Swiper>
        </div>
      </div>
    </section>
    </>
  );
}
